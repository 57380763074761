import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProductNew = ({
  title,
  topText,
  productText,
  buttonText,
  buttonLink,
  productImg,
}) => {
  const data = useStaticQuery(graphql`
    query ProductNewQuery {
      productImgPlaceholder: file(
        relativePath: { eq: "bg/mic-reverse.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  const productImgPlaceholder = getImage(data.productImgPlaceholder);

  let description = productText;

  return (
    <div className="relative bg-gradient-to-r from-gray-800 via-gray-800 to-red-700 shadow-lg rounded-md mb-6 lg:mb-3 z-10">
      {productImg ? (
        <div className="absolute right-0 bottom-0 w-56 z-00 mb-4">
          <GatsbyImage
            image={productImg ? productImg : productImgPlaceholder}
            alt=""
          />
        </div>
      ) : null}

      <div className="relative px-8 py-12 z-30 h-full flex flex-col">
        <div className="flex-grow">
          {topText ? (
            <p className="text-white text-2xl uppercase mb-2 font-bold tracking-widest">
              {topText ? topText : 'New!'}
            </p>
          ) : null}
          <h2 className="text-3xl xl:text-4xl font-extrabold tracking-tight text-red-100">
            <span className="block leading-snug tracking-wide">
              {title ? title : 'Product Title'}
            </span>
          </h2>
          <p className="max-w-sm text-white mt-2">
            {description ? description : null}
          </p>
        </div>
        <div className="mt-8 align-bottom justify-end">
          <div className="inline-flex rounded-md">
            <Link to={buttonLink ? buttonLink : '/dashboard'}>
              <button className="inline-flex items-center justify-center hover:bg-red-900 px-4 md:px-8 py-3 border border-transparent text-base md:text-lg font-bold rounded-md text-red-900 hover:text-white tracking-wider bg-white hover:shadow-xl">
                {buttonText ? buttonText : 'Button Name'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductNew.propTypes = {
  title: PropTypes.string,
  productText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default ProductNew;
