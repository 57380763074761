import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import ProductNew from '../Common/ProductNew';

const ProductCollateralSectionNew = () => {
  const data = useStaticQuery(graphql`
    query ProductCollateralSectionNewQuery {      
      prismicSalesSupportDocumentsProductCollateral {
        data {
          section_title
          section_description {
            richText
          }
        }
      }
      allPrismicSraProductPage(sort: { fields: data___card_position, order: ASC }) {
        edges {
          node {
            id
            uid
            data {
              title
              description
              button_text
              card_position
            }
          }  
        }        
      }
    }
  `);

  const doc = data.prismicSalesSupportDocumentsProductCollateral
  const products = data.allPrismicSraProductPage.edges

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold mb-6'>{doc.data.section_title}</h2>
        {doc.data.section_description
        ?
        <RichText
          render={doc.data.section_description.richText}
          htmlSerializer={htmlSerializer}
        />
        :
        null
        }
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-0 md:gap-2 xl:gap-8'>
        {products.map( item => {
          return (
            <ProductNew
              title={item.node.data.title}
              productText={item.node.data.description}
              buttonLink={`/sales-support-documents/collateral/${item.node.uid}`}
              buttonText={item.node.data.button_text}
            />  
          )
        })}        
      </div>
    </>
  )
}

export default ProductCollateralSectionNew 