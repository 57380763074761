import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../../../components/Dashboard/DashboardWrapper';
import Layout from '../../../components/layout';
import { withAuthorization } from '../../../components/Session';
import HeadData from '../../../data/HeadData';
import BackButton from '../../../components/Common/BackButton';
import ProductCollateralSectionNew from '../../../components/Sections/ProductCollateralSectionNew';

const CollateralPageBase = () => (
  <DashboardWrapper pageName="Product Collateral">
    <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <ProductCollateralSectionNew />

        <BackButton buttonLink="/sales-support-documents" />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = (authUser) => !!authUser;

const CollateralPage = compose(withAuthorization(condition))(
  CollateralPageBase,
);

const Collateral = () => (
  <Layout>
    <HeadData title="Product Collateral" />
    <CollateralPage />
  </Layout>
);

export default Collateral;
